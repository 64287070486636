<script setup lang="ts">
import type { Partner_Plain } from '@webamboos/admin'

const props = defineProps<{
  partners: Partner_Plain[]
}>()
</script>
<template>
  <div>
    <Paragraph size="normal" weight="normal" align="center" class="!text-gray-400">
      {{ $t('home.partners_title') }}
    </Paragraph>

    <div class="mt-4 grid grid-cols-2 flex-wrap items-center justify-center gap-8 lg:flex lg:gap-12">
      <template v-for="partner in props.partners" :key="partner.name">
        <NuxtImg
          v-if="partner.logo"
          :src="partner.logo?.url"
          :alt="partner.name"
          :height="partner.logo?.height"
          :width="partner.logo?.width"
          loading="lazy"
          class="mx-auto max-h-[15rem] max-w-[7.5rem] object-contain lg:mx-0"
        />
      </template>
    </div>
  </div>
</template>
