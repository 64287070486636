<script setup lang="ts">
import type { Media_Plain } from '@webamboos/admin'

const { clients } = useHome()

const ceo = {
  name: 'Maxim Rotaru',
  image: {
    url: '/local/ceo.jpg',
    alternativeText: 'Maxim Rotaru, Founder & CEO of Webamboos',
    width: 64,
    height: 64,
  } as Media_Plain,
  role: 'Founder & CEO of',
  partnerName: 'Webamboos',
}
</script>

<template>
  <Container v-if="clients" class="screen-content flex flex-col items-center px-2 py-16">
    <Heading size="medium" as="h2" class="!text-main-950 text-center">
      {{ clients.ceo_message }}
    </Heading>
    <Person :person="ceo" class="mt-4" />
    <Testimonials :testimonials="clients.testimonials" class="mt-16 w-full" />
    <PlatformsReviews class="mt-16" />
    <Partners :partners="clients.partners" class="mt-12 px-10 lg:px-0" />
  </Container>
</template>
